/* Apply a global reset to ensure consistency */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif; /* Set Inter font, with a fallback */
  line-height: 1.5; /* Improved readability */
}

a {
  text-decoration: none;
  display: block;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

input {
  background-color: transparent;
  width: 100%;
}

input:focus {
  outline: none;
}
