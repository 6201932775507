@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /*------Color variables------*/
    --black_900_14: #00000014;
    --blue_gray_400: #818198;
    --blue_gray_900: #211f50;
    --gray_200: #f3f0ee;
    --gray_300: #e3dedb;
    --gray_700: #565666;
    --gray_900: #050430;
    --green_600: #25b93c;
    --light_blue_700: #0081c9;
    --purple_400: #b23c5f; /* Corrected */
    --white_a700: #ffffff;

    /*------Shadow variables------*/
    --shadow-xs: 0 8px 62px 0 #00000014;

    /*-----Border radius variables----*/
    --radius-xs: 5px;
    --radius-sm: 6px;
    --radius-md: 8px;
    --radius-lg: 10px; /* Corrected */
    --radius-xl: 16px;
    --radius-2xl: 20px;
    --radius-3xl: 24px;

    /*------Spacing variables ------*/
    --space-xs: 6px;
    --space-sm: 8px;
    --space-md: 10px;
    --space-lg: 12px; /* Corrected */
    --space-xl: 14px;
    --space-2xl: 16px;
    --space-3xl: 20px;
    --space-4xl: 22px;
    --space-5xl: 24px;
    --space-6xl: 26px;
    --space-7xl: 30px;
    --space-8xl: 36px;
    --space-9xl: 40px;
    --space-10xl: 48px;
    --space-11xl: 50px;
    --space-12xl: 60px;
  }

  [type="text"],
  input:where(:not([type])),
  [type="email"],
  [type="url"], /* Corrected */
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple], /* Corrected */
  textarea,
  select {
    appearance: none;
    background-color: transparent;
    border: none;
    border-width: 0;
  }
}

@layer components {
  .container-xs {
    @apply w-full mx-auto max-w-[1200px];
  }
  .text-outline-black {
    color: var(--white_a700);
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
      1px 1px 0 black;
  }
  .slider-blue .slider-track {
    background-color: #3b82f6; /* Blue track */
  }
  .slider-blue .slider-thumb {
    background-color: #1d4ed8; /* Dark blue thumb */
  }
}
