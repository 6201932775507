@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/RobotoRegular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/InterRegular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/RobotoMedium.ttf") format("truetype");
}

@font-face {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/LexendDeca-VariableFont_wght.ttf") format("truetype");
}
